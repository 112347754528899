import { Getters, Mutations, Actions, Module } from "vuex-smart-module";
import MeetingService from "@/services/MeetingService";
import store, { YacXStore } from "..";
import { Store } from "vuex";
import axios from "axios";

const meetingService = new MeetingService();

export class MeetingState {
  uploading: boolean = false;
}

class MeetingGetters extends Getters<MeetingState> {}

class MeetingMutations extends Mutations<MeetingState> {
  uploading(current: boolean) {
    this.state.uploading = current;
  }
}

class MeetingActions extends Actions<
  MeetingState,
  MeetingGetters,
  MeetingMutations,
  MeetingActions
> {
  async createMeeting(payload: { name: string; slug?: string }) {
    if (!store.state.user.user) {
      throw new Error("User is not logged in.");
    }
    return meetingService.createMeeting(payload, store.state.user.user.id);
  }

  async archiveMeeting(payload: { id: string }) {
    await meetingService.archiveMeeting(payload.id);
  }

  async unarchiveMeeting(payload: { id: string }) {
    await meetingService.unarchiveMeeting(payload.id);
  }

  async setMeetingEndNotes(payload: { meetingId: string; endNotes: string }) {
    await meetingService.setMeetingEndNotes(
      payload.meetingId,
      payload.endNotes
    );
  }

  async deleteVideo(payload: { meetingId: string; id: string }) {
    await meetingService.deleteVideo(payload.meetingId, payload.id);
  }

  async updateMeeting({ id, ...rest }: { id: string; name: string }) {
    await meetingService.updateMeeting(id, rest);
  }

  async googleDocsExport(payload: { id: string }) {
    await meetingService.googleDocsExport(payload.id);
  }

  async notionExport(payload: { id: string }) {
    await meetingService.notionExport(payload.id);
  }

  async docxExport(payload: { id: string }) {
    await meetingService.docxExport(payload.id);
  }

  async addMeetingParticipant({
    meetingId,
    automatic,
    ...participant
  }: {
    meetingId: string;
    automatic: boolean;
  } & ({ id: string } | { email: string })) {
    await axios.post(
      "https://europe-west1-yac-chanel.cloudfunctions.net/meetingNewParticipant",
      { meeting: meetingId, participant, automatic }
    );
  }

  async removeMeetingParticipant({
    meetingId,
    automatic,
    ...participant
  }: {
    meetingId: string;
    automatic: boolean;
  } & ({ id: string } | { email: string })) {
    await axios.delete(`/meeting/${meetingId}/participant/${participant}`);
  }

  async addVideo(payload: {
    meetingId: string;
    getNewVideoIndex?: (newVideoId: string) => string[];
    file: File;
    onUploadProgress: (progress: number) => void;
    byUserId: string;
    video: boolean;
    duration: number;
    extension?: string;
    title: string;
    store: Store<YacXStore>;
    type: "SCREEN_SHARE" | "VOICE";
    kickoff?: boolean;
  }) {
    await meetingService.addVideo({
      ...payload,
      liveTranscriptionData: store.state.transcription.transcriptionData,
    });
    store.commit("transcription/CLEAR_TRANSCRIPTION_DATA");
  }

  async setVideo({
    meetingId,
    id,
    ...rest
  }: {
    meetingId: string;
    id: string;
    title?: string;
  }) {
    await meetingService.setVideo(meetingId, id, rest);
  }

  async markVideoAsRead({
    meetingId,
    videoId,
    userId,
  }: {
    meetingId: string;
    videoId: string;
    userId?: string;
  }) {
    await meetingService.markVideoAsRead(meetingId, videoId, userId);
  }

  async setVideoIndex({
    meetingId,
    newVideoIndex,
  }: {
    meetingId: string;
    newVideoIndex: string[];
  }) {
    await meetingService.setVideoIndex(meetingId, newVideoIndex);
  }

  async setAgendaItems({
    meetingId,
    agendaItems,
  }: {
    meetingId: string;
    agendaItems: { name: string; description: string; done: boolean }[];
  }) {
    await meetingService.setAgendaItems(meetingId, agendaItems);
  }

  async sendChatMessage({
    meetingId,
    ...rest
  }: {
    meetingId: string;
    text: string;
    files: File[];
  }) {
    const stateUser = store.state.user.user;
    if (!stateUser) throw new Error("User is not logged in.");
    await meetingService.sendChatMessage(meetingId, rest, stateUser.id);
  }

  async setMeetingNotes({
    meetingId,
    notes,
  }: {
    meetingId: string;
    notes: string;
  }) {
    await meetingService.setMeetingNotes(meetingId, notes);
  }
}

// Create a module with module asset classes
const MeetingModule = new Module({
  namespaced: true,
  state: MeetingState,
  getters: MeetingGetters,
  mutations: MeetingMutations,
  actions: MeetingActions,
});

export default MeetingModule;
