<template>
  <div class="flex flex-col h-full w-full select-none space-y-3 pt-4">
    <ul
      class="py-1"
      style="border: 1px solid transparent"
      aria-labelledby="dropdownButton"
    >
      <li
        v-for="(participant, i) in orderedParticipantsLogged"
        :key="`participant-${i}`"
        class="flex items-center gap-4 p-4 participant-box transition-all duration-100"
      >
        <img
          v-if="participant.photoURL && participant.photoURL.length > 0"
          class="w-12 h-12 rounded-full"
          :src="participant.photoURL"
          :alt="participant.displayName"
        />
        <div
          class="rounded-full grid place-items-center w-12 h-12"
          style="background-color: #f5f2ee"
          v-else-if="participant.displayName"
        >
          {{ userInitials(participant.displayName) }}
        </div>
        <div class="flex-col" style="max-width: 75%">
          <strong class="text-sm"
            >{{ participant.displayName
            }}{{
              user.displayName === participant.displayName && user.displayName
                ? " (you)"
                : ""
            }}</strong
          >
          <p class="text-sm email-wrapper">{{ participant.email }}</p>
        </div>
      </li>
    </ul>
    <div
      v-if="orderedParticipantsAnonymous.length"
      class="w-full"
      style="padding: 0px 20px; border-width: 0px"
    >
      <hr />
    </div>
    <ul
      v-if="orderedParticipantsAnonymous.length"
      style="border: 1px solid transparent"
      class="py-1"
      aria-labelledby="dropdownButton"
    >
      <li
        v-for="(participant, i) in orderedParticipantsAnonymous"
        :key="`participant-${i}`"
        class="flex items-center gap-4 p-4 participant-box participant-box-anonymous transition-all duration-100"
      >
        <img
          class="w-12 h-12 rounded-full"
          :src="participant.photoURL"
          :alt="participant.displayName"
          onerror="this.src='https://ui-avatars.com/api/?name=&background=ededed&size=128&bold=true&color=000000'"
        />
        <div class="flex-col" style="max-width: 75%">
          <strong class="text-sm"
            >Anonymous {{ participant.displayName
            }}{{
              user.displayName === participant.displayName && user.displayName
                ? " (you)"
                : ""
            }}</strong
          >
          <p class="text-sm email-wrapper whitespace-nowrap">
            {{ participant.email }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    participants: null,
  },
  mounted() {
    this.$emit("get-participants");
  },
  methods: {
    userInitials(displayName) {
      const words = displayName.toUpperCase().split(" ");
      const initials = words.reduce((ret, next) => {
        if (/[A-Z]/.test(next[0])) {
          return ret + next[0];
        }
        return ret;
      }, "");
      return initials;
    },
  },
  computed: {
    ...mapState("user", {
      user: "user",
      userLoading: "loading",
    }),
    participantUsers() {
      return this.participants;
    },
    orderedParticipantsLogged() {
      if (this.user) {
        const withoutUser = this.participantUsers.filter(
          (u) => u.id !== this.user.id
        );
        return [{ ...this.user, current: true }, ...withoutUser].filter(
          (u) => !u.isAnonymous
        );
      }
      return this.participantUsers.filter((u) => !u.isAnonymous);
    },
    orderedParticipantsAnonymous() {
      return this.participantUsers.filter((u) => u.isAnonymous);
    },
  },
};
</script>

<style></style>
