














































import Vue from "vue";
import BlankHeader from "@/components/core/BlankHeader.vue";
import Footer from "@/components/core/Footer.vue";
import PageHeaderText from "@/components/typography/PageHeaderText.vue";
import Email from "@/components/svg/Email.vue";
import GoogleG from "@/components/svg/GoogleG.vue";
import Button from "@/components/core/Button.vue";

export default Vue.extend({
  components: {
    BlankHeader,
    Footer,
    PageHeaderText,
    Email,
    GoogleG,
    Button,
  },
  metaInfo: {
    title: "Yac | Login",
  },
  data() {
    return {
      meeting: {
        name: "",
      },
      error: false as false | string,
      success: false as false | string,
      loading: false,
      moreOptions: false,
      inMove: false,
      activeSection: 0,
      offsets: [],
      touchStartY: 0,
    };
  },
  methods: {
    samlLogin() {
      this.$store.dispatch("auth/samlLogin").then(() => {});
    },
    googleLogin() {
      this.$store.dispatch("auth/googleLogin").then(() => {});
    },
  },
});
