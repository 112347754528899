










































































import Vue from "vue";
import { mapState } from "vuex";
import Logo from "../svg/Logo.vue";
import { sleep } from "@/util";
import Button from "./Button.vue";
import UserOptionsDropdown from "../user/UserOptionsDropdown.vue";
export default Vue.extend<any, any, any, any>({
  components: {
    Logo,
    Button,
    UserOptionsDropdown,
  },
  data() {
    return {
      isMobile:
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ),
      loading: false,
    };
  },
  methods: {
    linkWithEmailAndPassword() {
      this.loadingEmailAndPassword = true;
      this.error = "";
      this.$store
        .dispatch("auth/linkWithEmailAndPassword", {
          email: this.loginForm.email,
          password: this.loginForm.password,
        })
        .then(() => sleep(500))
        .then(() =>
          this.$store.dispatch("user/loadUser", {
            prioritizeFirebaseUserFields: true,
          })
        )
        .then(() => {
          this.showModal = false;
          this.loginForm.email = "";
          this.loginForm.password = "";
        })
        .catch((error: any) => {
          this.error = error.message || "Something went wrong.";
        })
        .finally(() => {
          this.loadingEmailAndPassword = false;
        });
    },
    linkWithGoogle() {
      this.loadingGoogle = true;
      this.error = "";
      this.$store
        .dispatch("auth/linkWithGoogle")
        .then(() => sleep(500))
        .then(() =>
          this.$store.dispatch("user/loadUser", {
            prioritizeFirebaseUserFields: true,
          })
        )
        .then(() => {
          this.showModal = false;
        })
        .catch((error: any) => {
          this.error = error.message || "Something went wrong.";
        })
        .finally(() => {
          this.loadingGoogle = false;
        });
    },
    logout() {
      this.loading = true;
      this.error = "";
      this.$store
        .dispatch("auth/logout")
        .then(() => {})
        .catch((error: any) => {
          this.error = error.message || "Something went wrong.";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getUserOptionsActions() {
      if (this.isMobile) return this.user.email ? [] : ["editProfile"];
      else
        return this.user.email
          ? ["openExisting"]
          : ["openExisting", "editProfile"];
    },
  },
  computed: {
    ...mapState("user", {
      storeUser: "user",
      storeUserLoading: "loading",
    }),
    // this is required for instant component render
    user() {
      return this.storeUser || {};
    },
    userDisplayName() {
      if (this.user.realName) return this.user.realName;
      if (this.user.displayName) return this.user.displayName;
      if (this.user.email) return this.user.email;
      return "";
    },
    userInitials() {
      if (this.user.displayName) {
        const words: [] = this.user.displayName.toUpperCase().split(" ");
        const initials = words.reduce((ret, next) => {
          if (/[A-Z]/.test(next[0])) {
            return ret + next[0];
          }
          return ret;
        }, "");
        return initials;
      }
      if (this.user.email) return this.user.email;
      return "";
    },
    userLoading() {
      return this.storeUserLoading || true;
    },
  },
});
