














































































import Vue from "vue";
import { mapState } from "vuex";
import UserLoginModal from "../user/UserLoginModal.vue";
export default Vue.extend({
  components: {
    UserLoginModal,
  },
  data() {
    return {
      message: {
        title: "",
        status: "",
        by: "",
        photoURL: "",
      },
      showModal: false,
    };
  },
  props: {
    archived: {
      type: Boolean,
      default: false,
    },
    photoURL: {
      type: String,
      default: "",
    },
    meetingId: {
      type: String,
      required: true,
      default: "",
    },
  },
  methods: {
    handleClick() {
      if (!this.archived) this.$emit("startRecording");
    },
  },
  computed: {
    ...mapState("user", {
      user: "user",
      userLoading: "loading",
    }),
    userInitials() {
      if (this.user.displayName) {
        const words: [] = this.user.displayName.toUpperCase().split(" ");
        const initials = words.reduce((ret, next) => {
          if (/[A-Z]/.test(next[0])) {
            return ret + next[0];
          }
          return ret;
        }, "");
        return initials;
      }
      if (this.user.email) return this.user.email;
      return "";
    },
  },
});
