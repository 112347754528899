import { Getters, Mutations, Actions, Module } from "vuex-smart-module";
import AuthService from "@/services/AuthService";
import firebase from "firebase/app";
import store from "..";
import Axios from "axios";

// What is this?
const NO_EMULATORS = true;
let axios = Axios.create();
if (process.env.NODE_ENV === "development" && NO_EMULATORS) {
  axios = Axios.create({
    baseURL: "https://cc.yac.com",
  });
}

const authService = new AuthService();

export class AuthState {}

class AuthGetters extends Getters<AuthState> {}

class AuthMutations extends Mutations<AuthState> {}

class AuthActions extends Actions<
  AuthState,
  AuthGetters,
  AuthMutations,
  AuthActions
> {
  async samlLogin() {
    authService.auth
      .signInWithPopup(authService.providers.Chanel)
      .then((userCredential) => {
        store.dispatch("user/updateProperties", { userCredential });
        console.log("SAML USER", userCredential);
      })
      .catch((err) => {
        console.log("SAML ERROR", err);
      });
  }

  async googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    const userChangePromise = new Promise((resolve) => {
      authService.auth.signInWithPopup(provider).then(async (newUser) => {
        resolve(newUser);
      });
    });
    return userChangePromise;
  }

  async linkWithGoogle() {
    if (!authService.currentUser) {
      throw new Error("We couldn't find your profile.");
    }
    await authService.currentUser.linkWithPopup(
      new authService.providers.Google()
    );
  }

  async loggedIn() {
    console.log("loggedIn");
    if (authService.currentUser) {
      const token = await authService.currentUser.getIdToken();
      const url = "/auth/login";

      try {
        await Axios.post(url, { token }, { withCredentials: true });
      } catch (e: any) {
        if (e.response.status === 401) {
          console.log("UNRECOGNIZED USER");
        }
      }
    }
  }

  async logout() {
    await authService.logout();
    store.dispatch("user/clear");
  }
}

// Create a module with module asset classes
const AuthModule = new Module({
  namespaced: true,
  state: AuthState,
  getters: AuthGetters,
  mutations: AuthMutations,
  actions: AuthActions,
});

export default AuthModule;
