import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

// @ts-ignore
import AudioVisual from "vue-audio-visual";
import VueMeta from "vue-meta";
import VueCompositionAPI from "@vue/composition-api";
import { firestorePlugin } from "vuefire";
import { app } from "./services/FirebaseService";
import PortalVue from "portal-vue";
import VueWindowSizePlugin from "vue-window-size";
import firebase from "firebase/app";
// @ts-ignore
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import AnalyticsService from "@/services/AnalyticsService";

const analyticsService = new AnalyticsService();

const style =
  "color: tomato; background:#eee; -webkit-text-stroke: 1px black; font-size:30px;";
console.log(
  "%cWelcome to YacX! This is a beta version of our new product, check it out and feel free to let us know what you think! 🎉",
  style
);
// if (process.env.NODE_ENV !== "development") {
//   console.log = function () {};
// }

Vue.use(PortalVue);
// @ts-ignore
Vue.use(AudioVisual);
Vue.use(firestorePlugin);
Vue.use(VueMeta);
Vue.use(VueCompositionAPI);
Vue.use(VueWindowSizePlugin, { delay: 500 });
Vue.use(VuePlyr, {
  plyr: {
    settings: ["speed"],
    resetOnEnd: true,
    ratio: "16:9",
    autopause: true,
    hideControls: true,
    invertTime: false,
    speed: {
      selected: 1.5,
    },
    fullscreen: {
      enabled: true,
      iosNative: true,
    },
    storage: {
      enabled: true,
      key: "plyr",
    },
    disableContextMenu: false,
    controls: [
      "play-large",
      "play",
      "progress",
      "current-time",
      // "mute",
      // "volume",
      "captions",
      "settings",
      // "pip",
      // "airplay",
      "fullscreen",
    ],
  },
});

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  store,
  router,
  data() {
    return {
      unsubscribe: null as null | firebase.Unsubscribe,
    };
  },
  render: (h) => h(App),
  created() {
    this.unsubscribe = app.auth().onAuthStateChanged(async (user) => {
      if (user) {
        if (this.$route.path.includes("login")) {
          console.log("ROUTE", this.$route.path);
          this.$router.push("/");
        }
        await store.dispatch("user/loadUser", user);
      } else if (!this.$route.path.includes("login")) {
        this.$router.push("/login");
      }
    });
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
});
