import { render, staticRenderFns } from "./UserLoginModal.vue?vue&type=template&id=08bdc030&"
import script from "./UserLoginModal.vue?vue&type=script&lang=ts&"
export * from "./UserLoginModal.vue?vue&type=script&lang=ts&"
import style0 from "./UserLoginModal.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports