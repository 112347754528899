














































































































import Vue from "vue";
import Modal from "@/components/core/Modal.vue";
import FormItem from "../form/FormItem.vue";
import Logo from "../svg/Logo.vue";
import Email from "../svg/Email.vue";
import GoogleG from "../svg/GoogleG.vue";
import Button from "../core/Button.vue";
import PageHeaderText from "../typography/PageHeaderText.vue";

export default Vue.extend<any, any, any, any>({
  components: {
    Modal,
    Logo,
    Button,
    FormItem,
    Email,
    GoogleG,
    PageHeaderText,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "Login",
    },
    hideNameInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    meetingId: {
      type: String,
      required: false,
      default: "",
    },
    emailCompletionPrompt: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      error: "",
      loadingSetSavedUser: false,
      loginWithEmail: false,
      checkEmail: false,
      userEmailCopy: "",
      userRealName: "",
    };
  },
  methods: {
    samlLogin() {
      this.$store.dispatch("auth/samlLogin").then((returnObject: any) => {
        console.log("RETURN OBJECT", returnObject);
        this.$emit("success");
        this.$emit("close");
      });
    },
    googleLogin() {
      this.$store.dispatch("auth/googleLogin").then((returnObject: any) => {
        console.log("RETURN OBJECT", returnObject);
        this.$emit("success");
        this.$emit("close");
      });
    },
    logInUser() {
      this.error = "";
      this.loadingSetSavedUser = true;
      // const prevUserId = this.user.id;
      this.$store
        .dispatch("auth/login", this.userEmailCopy)
        .then((data: any | null) => {
          console.log("DATA", data);
          // if(data && data.token){
          //   return this.$store.dispatch("auth/tokenLogin", data.token);
          // }
          return Promise.resolve();
        })
        .then(() => {
          this.checkEmail = true;
        })
        .catch((error: any) => {
          this.error = error.message || "Something went wrong.";
        })
        .finally(() => {
          this.loadingSetSavedUser = false;
        });
    },
    cancelOperation() {
      this.$emit("cancel");
      this.$emit("close");
    },
  },
  computed: {
    user() {
      return this.$store.state.user.user || {};
    },
  },
  watch: {
    user: {
      handler(user: any) {
        if (user.email) {
          this.userEmailCopy = user.email;
        }
      },
      deep: true,
    },
    showModal: {
      handler() {
        this.userEmailCopy = (this.$store.state.user.user || {}).email || "";
        this.loginWithEmail = false;
      },
    },
  },
  mounted() {
    this.checkEmail = false;
    this.userEmailCopy = (this.$store.state.user.user || {}).email || "";
  },
});
