import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/analytics";
import axios from "axios";

//CHANEL PRODUCTION
const firebaseConfig = {
  apiKey: "AIzaSyBGbPEDkxeQZkEH6ti2-7Nkd_dpMeQSLNQ",
  authDomain: "yac-chanel.firebaseapp.com",
  projectId: "yac-chanel",
  storageBucket: "yac-chanel.appspot.com",
  messagingSenderId: "566603627082",
  appId: "1:566603627082:web:7fa84953c42650c6dec4b3",
};

export const app = firebase.initializeApp(firebaseConfig);

// if (location.hostname === "localhost") {
//   app.firestore().useEmulator("localhost", 9090);
//   app.storage().useEmulator("localhost", 9199);
//   app.auth().useEmulator("http://localhost:9099");
// }

export default class FirebaseService {
  auth = app.auth();
  db = app.firestore();
  analytics = app.analytics();
  storage = app.storage();
  providers = {
    Google: firebase.auth.GoogleAuthProvider,
    Chanel: new firebase.auth.SAMLAuthProvider("saml.chanel-yac-integration"),
  };
  meetingsCol = this.db.collection("meetings");
  usersCol = this.db.collection("users");
  userRelationsCol = this.db.collection("userRelations");
  public get currentUser() {
    return this.auth.currentUser;
  }
}

function connectFirestoreEmulator(db: any, arg1: string, arg2: number) {
  throw new Error("Function not implemented.");
}
