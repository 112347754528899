




















































































import { mapState } from "vuex";
import { ResolvedMeeting } from "@/models/Meeting";
import Button from "@/components/core/Button.vue";
import Spinner from "@/components/svg/Spinner.vue";
import LockIcon from "@/components/svg/LockIcon.vue";
import { copyToClipboard, sleep } from "@/util";
import Vue from "vue";
import { app } from "@/services/FirebaseService";
import firebase from "firebase/app";

export default Vue.extend<any, any, any, any>({
  components: {
    Button,
    LockIcon,
    Spinner,
  },
  data() {
    return {
      meetings: [] as ResolvedMeeting[],
      meetingsSize: 5,
      error: false as false | string,
      success: false as false | string,
      loading: false as boolean,
    };
  },
  mounted() {
    this.loading = true;
  },
  computed: {
    ...mapState("user", {
      user: "user",
    }),
    meetingsList() {
      return this.meetings.slice(0, this.meetingsSize);
    },
    showMeetingHistory: function () {
      const show =
        this.user && !this.user.isAnonymous && this.user.plan === "PRO";
      if (show) {
        this.loadMeetings();
      }
      return show;
    },
  },
  methods: {
    copyLink(slug: String) {
      copyToClipboard(`${window.location.origin}/m/${slug}`);
      this.success = "Copied to clipboard!";
    },
    gotoMeeting(slug: String) {
      this.$router.push("/m/" + slug);
    },
    async loadMeetings() {
      this.$bind(
        "meetings",
        app
          .firestore()
          .collection("meetings")
          .where("users", "array-contains", this.user.id)
          .orderBy("dateCreated", "desc")
      ).finally(() => {
        this.loading = false;
      });
    },
    showMore() {
      this.meetingsSize += this.meetingsSize;
      const anchor = this.$refs.anchor;
      sleep(100).then(() => {
        anchor.scrollIntoView({
          block: "start",
          behavior: "smooth",
          inline: "start",
        });
      });
    },
    parseDate(ts: firebase.firestore.Timestamp) {
      const d = ts.toDate();
      const month = d.toLocaleString("en-US", { month: "short" }).toUpperCase();
      const day = d.getDate();
      const year = d.getFullYear();
      if (year === new Date().getFullYear()) {
        return `${month} ${day}`;
      } else {
        return `${month} ${day} '${year.toString().slice(2)}`;
      }
    },
  },
});
