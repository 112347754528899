import { render, staticRenderFns } from "./MeetingFooter.vue?vue&type=template&id=973d45fc&"
import script from "./MeetingFooter.vue?vue&type=script&lang=js&"
export * from "./MeetingFooter.vue?vue&type=script&lang=js&"
import style0 from "./MeetingFooter.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports